<template>
     <InertiaHead title="Support"></InertiaHead>
     <div class="moozi-min-h w-full h-full relative flex justify-center">
          <div class="w-full 2xl:max-w-screen-2xl bg-transparent relative">
               <div
                    class="w-full h-full flex flex-col justify-center md:items-center pb-20 lg:pb-26 pt-0"
               >
                    <div
                         class="banner flex bg-no-repeat bg-cover flex-col items-center w-full 2xl:max-w-screen-2xl"
                    >
                         <h1
                              class="text-white font-helvetica tracking-wide text-center text-5xl xs:text-5xl sm:text-5xl md:text-6xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl"
                         >
                              FAQ
                         </h1>
                         <div class="w-full flex items-center">
                              <div
                                   class="w-full text-white flex flex-col items-center"
                              >
                                   <div
                                        class="relative w-full pt-8 lg:pt-4 pb-3 lg:pb-4 flex items-center"
                                   >
                                        <div
                                             class="h-12 flex items-center sm:h-0 sm:block ml-4 absolute pointer-events-auto"
                                        >
                                             <svg
                                                  class="absolute text-slate-400 h-5 w-5"
                                                  viewBox="0 -3 20 20"
                                                  fill="#684041"
                                             >
                                                  <path
                                                       fill-rule="evenodd"
                                                       d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                       clip-rule="evenodd"
                                                  />
                                             </svg>
                                        </div>
                                        <input
                                             type="text"
                                             @input="change($event)"
                                             class="w-full rounded-full border-moozi-4 focus:border-moozi-4 focus:ring-moozi-4 text-white font-roboto text-sm sm:text-base md:text-lg lg:text-base xl:text-lg placeholder:text-moozi-4 h-10 lg:h-12 mt-0 sm:mt-6 pl-10 bg-moozi-2"
                                             placeholder="Search"
                                             style=""
                                        />
                                   </div>
                                   <div
                                        class="pt-4 text-xl"
                                        v-if="searchQuery !== ''"
                                   >
                                        {{ selectedData.length }} search results
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div
                         class="game-custom-scrollbar rounded-md w-full relative block md:flex items-center justify-start 2xl:max-w-screen-2xl"
                    >
                         <div
                              class="game-custom-scrollbar-wrapper p-0 sm:p-0 w-full overflow-visible block md:flex items-center mb-0 md:mb-1 md:overflow-x-auto"
                              :class="menu ? 'relative md:static' : 'md:static'"
                         >
                              <!-- Support Buttons -->
                              <div
                                   class="flex md:hidden text-xl items-center justify-between px-8 w-full bg-moozi-2 text-white h-12 lg:h-14 rounded-t-lg lg:rounded-t-2xl z-10 relative"
                                   @click="
                                        () => {
                                             openMenu();
                                        }
                                   "
                              >
                                   <div
                                        class="w-full font-roboto font-medium text-center pl-4 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                   >
                                        {{ selected }}
                                   </div>
                                   <svg
                                        :class="menu ? 'rotate-180' : ''"
                                        class="transition-transform duration-300"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                   >
                                        <path
                                             fill-rule="evenodd"
                                             clip-rule="evenodd"
                                             d="M5.29279 7.29259C5.48031 7.10512 5.73462 6.99981 5.99979 6.99981C6.26495 6.99981 6.51926 7.10512 6.70679 7.29259L9.99979 10.5856L13.2928 7.29259C13.385 7.19708 13.4954 7.1209 13.6174 7.06849C13.7394 7.01608 13.8706 6.9885 14.0034 6.98734C14.1362 6.98619 14.2678 7.01149 14.3907 7.06177C14.5136 7.11205 14.6253 7.18631 14.7192 7.2802C14.8131 7.37409 14.8873 7.48574 14.9376 7.60864C14.9879 7.73154 15.0132 7.86321 15.012 7.99599C15.0109 8.12877 14.9833 8.25999 14.9309 8.382C14.8785 8.504 14.8023 8.61435 14.7068 8.70659L10.7068 12.7066C10.5193 12.8941 10.265 12.9994 9.99979 12.9994C9.73462 12.9994 9.48031 12.8941 9.29279 12.7066L5.29279 8.70659C5.10532 8.51907 5 8.26476 5 7.99959C5 7.73443 5.10532 7.48012 5.29279 7.29259Z"
                                             fill="white"
                                        />
                                   </svg>
                              </div>
                              <div
                                   :class="
                                        menu
                                             ? 'translate-y-0 opacity-100'
                                             : '-translate-y-10 opacity-0 -z-20 md:translate-y-0 md:opacity-100 md:z-0'
                                   "
                                   class="game-custom-scrollbar-catergory absolute overflow-x-auto md:mt-10 w-full md:flex md:flex-row md:w-full transition-all duration-500 ease-in-out"
                              >
                                   <button
                                        v-for="(item, key) in data"
                                        :key="key"
                                        @click="
                                             () => {
                                                  (tempSelect = item.name),
                                                       (selected = item.name),
                                                       select(selected),
                                                       openMenu();
                                             }
                                        "
                                        class="focus:outline-none md:px-7 py-3 lg:py-4 md:flex flex-col justify-center w-full md:w-auto items-center md:rounded-t-2xl font-roboto font-medium text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                        :class="[
                                             selected == item.name
                                                  ? 'hidden md:block text-white bg-moozi-2 border border-solid border-moozi-4'
                                                  : menu
                                                    ? ' bg-moozi-2 text-white md:bg-transparent'
                                                    : 'text-white md:border-0 bg-moozi-2 border border-solid border-moozi-4 md:bg-transparent',
                                             key + 1 == data.length
                                                  ? 'rounded-b-lg'
                                                  : ''
                                        ]"
                                        style="background-size: 100% 100%"
                                   >
                                        <span
                                             class="leading-4 whitespace-nowrap"
                                             >{{ item.name }}</span
                                        >
                                   </button>
                              </div>
                         </div>
                    </div>
                    <img
                         :src="baseUrl.img + '/assets/img/support/Vector.png'"
                         class="hidden w-screen sm:block -mt-1"
                         alt=""
                    />
                    <div class="w-full md:mt-11 pt-0.5 2xl:max-w-screen-2xl">
                         <FAQ
                              :changeMenu="changeMenu"
                              :data="selectedData"
                              :active="selected"
                         />
                    </div>
                    <!-- <CantFind /> -->
               </div>
          </div>
          <!-- <div class="w-full 2xl:max-w-screen-2xl bg-transparent relative">
               
          </div> -->
     </div>
</template>
<script>
import { onMounted, ref, computed } from "vue";
import { Head as InertiaHead } from "@inertiajs/vue3";
import _ from "underscore";
// import CantFind from "./components/cantFind.vue";
import FAQ from "./components/faq.vue";
import { faqStore } from "store/faq";
import { useScroll } from "composables/use-scroll.js";
import { baseUrl } from "config/services.js";

export default {
     components: {
          // CantFind,
          FAQ,
          InertiaHead
     },
     setup() {
          const { scrollTop } = useScroll();

          let val = ref("");
          let searchQuery = ref("");
          let searchData = ref([]);
          let selectedData = ref([]);
          let menu = ref(false);
          let changeMenu = ref(false);

          const useFaqStore = faqStore();
          let selected = ref("Redemptions");
          let tempSelect = ref("Redemptions");
          const data = computed(() => {
               if (useFaqStore.faq) {
                    searchData.value = _.filter(useFaqStore.faq, function (d) {
                         return d.name == selected.value;
                    });
                    selectedData.value = _.sortBy(
                         _.filter(useFaqStore.faq, function (d) {
                              return d.name == selected.value;
                         })[0]?.faq,
                         "position"
                    );
               }
               return _.sortBy(useFaqStore.faq, "position") || null;
          });

          onMounted(async () => {
               scrollTop();
               await useFaqStore.getFaq();
               selected.value = _.sortBy(useFaqStore.faq, "position")[0].name;
               tempSelect.value = _.sortBy(useFaqStore.faq, "position")[0].name;
          });

          const change = async (event) => {
               let arr = [];
               val.value = event.target.value;
               let tempArr = [];
               if (event.target.value == "") {
                    selected.value = tempSelect.value;
                    selectedData.value = _.sortBy(
                         _.filter(data.value, function (d) {
                              return d.name == "Redemptions";
                         })[0].faq,
                         "position"
                    );
               } else {
                    arr = JSON.parse(
                         JSON.stringify(
                              _.map(data.value, function (d) {
                                   return _.filter(d.faq, function (da) {
                                        return (
                                             da.title
                                                  .toLowerCase()
                                                  .includes(
                                                       event.target.value.toLowerCase()
                                                  ) ||
                                             da.body
                                                  .toLowerCase()
                                                  .includes(
                                                       event.target.value.toLowerCase()
                                                  )
                                        );
                                   });
                              })
                         )
                    );
                    arr.map((d) => {
                         d.map((da) => {
                              tempArr.push(da);
                         });
                    });
                    selectedData.value = _.sortBy(tempArr, "position");
                    selected.value = "";
               }
          };

          const select = async (event) => {
               selectedData.value = _.sortBy(
                    _.filter(data.value, function (d) {
                         return d.name == event;
                    })[0].faq,
                    "position"
               );
          };

          const openMenu = () => {
               menu.value = !menu.value;
          };

          window.addEventListener("resize", () => {
               menu.value = false;
          });

          return {
               change,
               openMenu,
               changeMenu,
               menu,
               val,
               select,
               selectedData,
               selected,
               tempSelect,
               searchData,
               searchQuery,
               data,
               baseUrl
          };
     }
};
</script>

<style></style>
