<template>
     <div class="pb-20 lg:pb-26 pt-3 sm:pt-5 md:pt-10 lg:pt-12 xl:pt-14">
          <span
               class="text-4xl text-white font-bold helvetica w-full text-center"
               >Notification</span
          >
          <div class="h-5/6 bg-white rounded-lg">
               <div
                    id="notification-dewacrm"
                    class="h-full overflow-y-auto"
               ></div>
          </div>
     </div>
</template>
