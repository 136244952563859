<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isGamePreview"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-auto overflow-x-hidden fixed z-80 w-full max-h-full transform ease-in-out transition-all duration-300 self-center m-auto inset-0 font-helveticaLight flex justify-center items-center"
               @click="
                    (e) => {
                         e.preventDefault();
                         e.stopPropagation();
                         closeModal();
                    }
               "
          >
               <div
                    v-if="game != null"
                    class="relative px-6 py-3 sm:py-4 sm:px-10 w-full max-w-xl max-h-full m-auto"
                    @click="
                         (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                         }
                    "
               >
                    <div
                         class="relative bg-gradient-benefits rounded-xl shadow-xl"
                    >
                         <button
                              @click="closeModal()"
                              type="button"
                              class="z-10 close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <div>
                              <img
                                   src=""
                                   alt=""
                              />
                         </div>
                         <img
                              class="absolute top-0 broken-image w-full rounded-t-xl h-80 bg-no-repeat bg-cover bg-center invisible"
                              :src="
                                   baseUrl.img +
                                   '/assets/img/loader/games-loader.png'
                              "
                              :alt="
                                   (game?.provider_id != null
                                        ? getProvider(game?.provider_id) + '-'
                                        : '') + game?.game_pic
                              "
                         />
                         <Carousel
                              v-if="game.screenshot != null"
                              :itemsToShow="1"
                              :wrapAround="false"
                              :mouseDrag="true"
                              :touchDrag="true"
                              snapAlign="start"
                              :transition="1300"
                              class="game-preview-slider-list relative w-full"
                              :autoplay="3000"
                         >
                              <Slide
                                   v-for="(item, key) in JSON.parse(
                                        game.screenshot
                                   )"
                                   :key="key"
                                   class="games-aspect-ratio-4-16"
                              >
                                   <img
                                        :src="item"
                                        alt="game"
                                        class="game-slider-thumbnails w-full rounded-t-xl h-80 bg-no-repeat bg-cover bg-center"
                                   />
                              </Slide>
                              <template #addons>
                                   <Pagination />
                              </template>
                         </Carousel>
                         <img
                              v-else-if="
                                   game?.game_pic != null &&
                                   game.screenshot == null
                              "
                              class="thumbnail-image w-full rounded-t-xl h-80 bg-no-repeat bg-cover bg-center"
                              :src="game.game_pic"
                              :alt="
                                   encodeURIComponent(
                                        (game.provider_id != null
                                             ? getProvider(game.provider_id) +
                                               '-'
                                             : '') +
                                             '-' +
                                             game.game_pic
                                   )
                              "
                              @error="onImageError"
                         />
                         <img
                              v-else-if="
                                   game.game_pic_mobile != null &&
                                   game.screenshot == null
                              "
                              class="thumbnail-image w-full rounded-t-xl h-80 bg-no-repeat bg-cover bg-center"
                              :src="game.game_pic_mobile"
                              :alt="
                                   encodeURIComponent(
                                        (game.provider_id != null
                                             ? getProvider(game.provider_id) +
                                               '-'
                                             : '') +
                                             '-' +
                                             game.game_pic
                                   )
                              "
                              @error="onImageError"
                         />
                         <img
                              v-else
                              class="thumbnail-image w-full rounded-t-xl h-80 bg-no-repeat bg-cover bg-center"
                              :src="
                                   baseUrl.img +
                                   '/assets/img/loader/games-loader.png'
                              "
                              :alt="
                                   (game.provider_id != null
                                        ? getProvider(game.provider_id) + '-'
                                        : '') + game.game_pic
                              "
                              @error="onImageError"
                         />
                         <div
                              class="flex flex-col divide-y divide-shade-12 items-center justify-center py-6 font-roboto text-shade-2 text-sm xs:text-base px-4 sm:px-6 lg:px-8 tracking-wide leading-tight"
                         >
                              <div
                                   class="w-full flex flex-col justify-center items-center font-normal relative"
                              >
                                   <p
                                        class="font-helvetica tracking-wide font-medium text-white text-xl xs:text-2xl sm:text-3xl lg:text-xl xl:text-2xl 2xl:text-3xl lg:leading-5 xl:leading-6 2xl:leading-7 text-center md:mb-4"
                                   >
                                        {{ game?.game_name }}
                                   </p>
                                   <div
                                        v-if="isUser"
                                        class="absolute top-0 right-0 rounded-xl z-[8]"
                                   >
                                        <span
                                             @click="onHeart(game?.id, isHeart)"
                                             :class="isHeart ? 'active' : ''"
                                             class="text-2xl md:text-2xl icon-addFavorites cursor-pointer"
                                        ></span>
                                   </div>
                                   <p
                                        @click="onRedirect()"
                                        class="cursor-pointer w-max flex flex-row items-center justify-center gap-2 font-roboto font-bold text-moozi-10 text-sm lg:text-base capitalize"
                                   >
                                        See more about the game
                                        <svg
                                             class="w-3"
                                             viewBox="0 0 9 14"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg"
                                        >
                                             <path
                                                  d="M8.16879 6.18665C8.6104 6.63659 8.6104 7.38072 8.16879 7.83066L2.44482 13.6625C2.0032 14.1125 1.27282 14.1125 0.83121 13.6625C0.389597 13.2126 0.389597 12.4685 0.83121 12.0185L5.75688 7L0.848211 1.98146C0.406598 1.53152 0.406598 0.787392 0.848211 0.337454C1.28982 -0.112485 2.02015 -0.112485 2.46177 0.337454L8.18574 6.16934L8.16879 6.18665Z"
                                                  fill="#3B0F11"
                                             />
                                        </svg>
                                   </p>
                              </div>
                         </div>
                         <div
                              class="w-full border-t-2 border-solid border-moozi-6"
                         ></div>
                         <div
                              class="flex flex-col items-center justify-center py-6 font-roboto text-shade-2 text-sm xs:text-base px-4 sm:px-6 lg:px-8 tracking-wide leading-tight"
                         >
                              <div class="flex flex-row w-full mb-8">
                                   <div
                                        class="w-6/12 flex flex-col items-center justify-center gap-3"
                                   >
                                        <p
                                             class="text-xxs lg:text-sm text-moozi-6 font-medium"
                                        >
                                             Provider
                                        </p>
                                        <p
                                             class="text-sm lg:text-base text-white font-bold capitalize"
                                        >
                                             {{
                                                  getProvider(game?.provider_id)
                                             }}
                                        </p>
                                   </div>
                                   <div
                                        class="w-6/12 flex flex-col items-center justify-center gap-3"
                                   >
                                        <p
                                             class="text-xxs lg:text-sm text-moozi-6 font-medium"
                                        >
                                             Type
                                        </p>
                                        <p
                                             class="text-sm lg:text-base text-white font-bold capitalize"
                                        >
                                             {{ getGameType(game?.game_type) }}
                                             <!-- {{ game?.game_type?.join(", ") }} -->
                                        </p>
                                   </div>
                              </div>
                              <div
                                   v-if="isUser"
                                   class="w-full"
                              >
                                   <div
                                        class="h-10 sm:h-11 flex gap-2 items-center md:relative tracking-widest p-1.5 bg-moozi-2 rounded-full !font-helveticaLight"
                                   >
                                        <div
                                             @click="setActiveCoinType('gold')"
                                             :class="
                                                  active_coin_type == 'gold'
                                                       ? 'bg-moozi-4 text-white'
                                                       : 'bg-transparent text-moozi-6'
                                             "
                                             class="w-6/12 h-full rounded-full coin-wrapper cursor-pointer"
                                             id="gold-coin-trigger"
                                        >
                                             <div
                                                  class="flex h-full items-center justify-start tracking-wider relative"
                                             >
                                                  <img
                                                       @click="
                                                            reloadBalance(
                                                                 'gold'
                                                            )
                                                       "
                                                       class="absolute left-0 top-0 cursor-pointer w-7 sm:w-8"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/GC.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <img
                                                       @click="
                                                            reloadBalance(
                                                                 'gold'
                                                            )
                                                       "
                                                       class="cursor-pointer w-7 sm:w-8 invisible"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/GC.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <span
                                                       :class="
                                                            active_coin_type ==
                                                            'gold'
                                                                 ? ''
                                                                 : 'block'
                                                       "
                                                       class="w-full text-xs sm:text-sm font-bold text-center pr-2"
                                                  >
                                                       {{
                                                            loadingGold
                                                                 ? ""
                                                                 : money(
                                                                        gold_balance
                                                                   )
                                                       }}
                                                       <loading-small
                                                            class="rounded-full"
                                                            :isLoading="
                                                                 loadingGold
                                                            "
                                                       />
                                                  </span>
                                             </div>
                                             <span class="text-left"></span>
                                        </div>
                                        <div
                                             @click="
                                                  setActiveCoinType('silver')
                                             "
                                             :class="
                                                  active_coin_type == 'silver'
                                                       ? 'bg-moozi-4 text-white'
                                                       : 'bg-transparent text-moozi-6'
                                             "
                                             class="w-6/12 h-full bg-background-2 rounded-full coin-wrapper cursor-pointer"
                                             id="silver-coin-trigger"
                                        >
                                             <div
                                                  class="flex h-full items-center justify-center tracking-wider relative"
                                             >
                                                  <img
                                                       @click="
                                                            reloadBalance(
                                                                 'silver'
                                                            )
                                                       "
                                                       class="cursor-pointer w-7 sm:w-8 invisible"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/SC.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <img
                                                       @click="
                                                            reloadBalance(
                                                                 'silver'
                                                            )
                                                       "
                                                       class="absolute left-0 top-0 cursor-pointer w-7 sm:w-8"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/SC.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <span
                                                       :class="
                                                            active_coin_type ==
                                                            'silver'
                                                                 ? ''
                                                                 : 'block'
                                                       "
                                                       class="w-full text-xs sm:text-sm font-bold text-center pr-2"
                                                  >
                                                       {{
                                                            loadingSilver
                                                                 ? ""
                                                                 : money(
                                                                        silver_balance
                                                                   )
                                                       }}
                                                       <loading-small
                                                            class="rounded-full"
                                                            :isLoading="
                                                                 loadingSilver
                                                            "
                                                       />
                                                  </span>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div
                                   @click="onLaunch(game, active_coin_type)"
                                   :class="
                                        active_coin_type == 'gold'
                                             ? 'bg-moozi-3'
                                             : 'bg-moozi-11'
                                   "
                                   class="w-full mt-7 h-10 sm:h-11 flex flex-row items-center justify-center gap-1 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider px-2 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                              >
                                   <span
                                        class="cursor-pointer font-roboto text-sm font-extrabold"
                                        >Play With
                                        {{
                                             active_coin_type == "gold"
                                                  ? "GC"
                                                  : "SC"
                                        }}</span
                                   >
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isGamePreview"
          class="fixed inset-0 z-60"
          style="background-color: rgba(0, 0, 0, 0.7)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, reactive, watch } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";
import { gamesStore } from "store/games";
import _ from "underscore";
import { authStore } from "store/auth.js";
import LoadingSmall from "@/components/loading/loadingSmall.vue";
import { useFormatter } from "composables/use-formatter.js";
import { useImage } from "composables/use-image.js";
import { useKYC } from "composables/use-kyc.js";
import { gameLauncher as gameLauncherAPI } from "@/api/seamless";
import { useToaster } from "composables/use-toaster.js";
import { useRouter, useRoute } from "vue-router";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import { setFavorites as setFavoritesAPI } from "@/api/games";

export default {
     components: {
          LoadingSmall,
          Carousel,
          Pagination,
          Slide
     },
     setup() {
          const { open_kyc_game_launcher } = useKYC();

          const game = ref(null);
          const onRedirect = ref(null);
          const isGamePreview = ref(false);
          const provider = ref(false);

          const isHeart = ref(false);

          const loadingGold = ref(false);
          const loadingSilver = ref(false);

          const apiParams = reactive({
               type: ""
          });

          const params = reactive({});

          const router = useRouter();
          const route = useRoute();

          const { money } = useFormatter();
          const { onImageError } = useImage();
          const { errorToast } = useToaster();

          const useAuthStore = authStore();
          const useGamesStore = gamesStore();

          const useUtilitiesStore = utilitiesStore();

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const heartData = computed(() => {
               return useGamesStore.heart || null;
          });

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          const active_coin_type = computed(() => {
               return useAuthStore.active_coin_type || route.params.type;
          });

          const gold_balance = computed(() => {
               return useAuthStore.gold_balance;
          });

          const silver_balance = computed(() => {
               return (
                    parseFloat(useAuthStore.silver_balance) +
                    parseFloat(useAuthStore.silver_balance_redeem)
               );
          });

          const gameFilter = computed(() => {
               const obj = Object.keys(useGamesStore.gameFilter);
               for (let index = 0; index < obj.length; index++) {
                    params[obj[index]] = useGamesStore.gameFilter[obj[index]];
               }

               return useGamesStore.gameFilter;
          });

          const closeModal = () => {
               isGamePreview.value = false;
               data.value = null;
               onRedirect.value = null;
               useUtilitiesStore.enableScroll();
          };

          const setActiveCoinType = (type) => {
               if (active_coin_type.value != type) {
                    useAuthStore.setActiveCoinType(type);
               }
          };

          const reloadBalance = async (val1) => {
               if (val1 == "gold") {
                    loadingGold.value = true;
                    apiParams.type = "gold_coins";
                    try {
                         await useAuthStore.reloadBalance(apiParams);
                         loadingGold.value = false;
                    } catch (error) {
                         console.error("Error gatting balance:", error);
                    }
               } else {
                    loadingSilver.value = true;
                    apiParams.type = "silver_coins";
                    try {
                         await useAuthStore.reloadBalance(apiParams);
                         loadingSilver.value = false;
                    } catch (error) {
                         console.error("Error gatting balance:", error);
                    }
               }
          };

          const getProvider = (id) => {
               const filter = _.filter(providerData.value, (params) => {
                    return params["id"] == id;
               });

               return filter[0]["name"];
          };

          const onLaunch = async (slug, type) => {
               if (isUser.value) {
                    if (open_kyc_game_launcher() == true) {
                         isGamePreview.value = false;
                         data.value = null;
                         onRedirect.value = null;
                         return;
                    }

                    await gameLauncherAPI({
                         game_slug: slug.game_slug,
                         type: type
                    })
                         .then((res) => {
                              if (res.status) {
                                   if (res) {
                                        if (res.launch_action == "redirect") {
                                             window.location.href = res.url;
                                             return;
                                        }
                                   }

                                   if (
                                        /iPad|iPhone|iPod/.test(
                                             navigator.userAgent
                                        )
                                   ) {
                                        // alert("This is an iOS device.");
                                        setTimeout(() => {
                                             //  window.open(res.url, "_blank");
                                             useAuthStore.setActiveCoinType(
                                                  type
                                             );
                                             useAuthStore.setActiveGameUrl(
                                                  res.url
                                             );
                                             useUtilitiesStore.enableScroll();
                                             router.push({
                                                  name: "play",
                                                  params: {
                                                       slug: slug.game_slug,
                                                       id: slug.provider_id,
                                                       launchcode:
                                                            res.launch_code,
                                                       type: type
                                                  }
                                             });
                                        });
                                   } else {
                                        useAuthStore.setActiveCoinType(type);
                                        useAuthStore.setActiveGameUrl(res.url);
                                        useUtilitiesStore.enableScroll();
                                        router.push({
                                             name: "play",
                                             params: {
                                                  slug: slug.game_slug,
                                                  id: slug.provider_id,
                                                  launchcode: res.launch_code,
                                                  type: type
                                             }
                                        });
                                   }
                              }
                         })
                         .catch((err) => {
                              if (
                                   !err?.response?.data?.status &&
                                   !err?.response?.data?.message ==
                                        "unauthorized"
                              ) {
                                   errorToast("Unauthorized!", "top-right");
                              } else {
                                   errorToast(
                                        "Something went wrong!",
                                        "top-right"
                                   );
                              }
                         });
               } else {
                    router.push({ name: "login" });
               }
          };

          const onHeart = async (id, status) => {
               const params = { game_id: id };

               if (status == true) {
                    params["unset"] = 1;
               }

               isHeart.value = !isHeart.value;

               const data = {
                    game_name: "",
                    game_promo: 0,
                    game_type: [],
                    provider_id: params.provider_id,
                    page: 1,
                    itemsPerPage: 21,
                    sortType: params.sortType
               };

               useGamesStore.getFavorites(data, true, false);
               await setFavoritesAPI(params);
               await useGamesStore.getHeart(true);
          };

          const getGameType = (data) => {
               if (typeof data == "object") {
                    return data.join(", ");
               } else {
                    return JSON.parse(data).join(", ");
               }
          };

          onMounted(async () => {
               eventBus.on("open:gamepreview", (data) => {
                    game.value = data.data;
                    if (heartData.value != null) {
                         isHeart.value = heartData.value.includes(
                              game.value?.id
                         );
                    }

                    onRedirect.value = () => {
                         useUtilitiesStore.enableScroll();
                         data.onPlay();
                    };

                    provider.value = _.filter(
                         providerData.value,
                         function (params) {
                              return params["id"] == game.value?.provider_id;
                         }
                    );

                    useUtilitiesStore.disableScroll();
                    isGamePreview.value = true;
               });

               eventBus.on("close:gamepreview", () => {
                    game.value = null;
                    isHeart.value = false;
                    onRedirect.value = null;
                    useUtilitiesStore.enableScroll();
                    isGamePreview.value = false;
               });
          });

          onUnmounted(() => {
               eventBus.off("open:gamepreview");
               eventBus.off("close:gamepreview");
          });

          watch(gameFilter, () => {});

          return {
               isGamePreview,
               closeModal,
               baseUrl,
               game,
               onRedirect,
               provider,
               money,
               reloadBalance,
               setActiveCoinType,
               gold_balance,
               silver_balance,
               active_coin_type,
               getProvider,
               onImageError,
               loadingGold,
               loadingSilver,
               onLaunch,
               isUser,
               heartData,
               onHeart,
               isHeart,
               getGameType
          };
     }
};
</script>
