<template>
     <div class="w-full h-full flex flex-col justify-between">
          <div>
               <p
                    class="text-shade-8 text-xs md:text-sm lg:text-xs xl:text-sm my-5"
               >
                    Connect your bank account for easier payment setup with no
                    expiration and faster refunds.
               </p>
               <div v-if="enrolled != null && enrolled?.account?.length">
                    <p
                         class="text-xs md:text-sm lg:text-xs xl:text-sm mb-5 text-black font-bold"
                    >
                         Current account
                    </p>
                    <div
                         v-if="
                              walletCount == 0 &&
                              enrolled?.account[0].accountStatus == '40'
                         "
                         class="h-full flex flex-col justify-between"
                    >
                         <div
                              class="border border-shade-10 py-3 px-4 w-full text-center text-shade-10 text-sm lg:text-base rounded-lg mb-4"
                         >
                              No bank connected. Please link new account.
                         </div>
                    </div>
                    <div
                         v-else
                         class="flex flex-col gap-2"
                    >
                         <div
                              v-for="(item, key) in getActiveAccount(
                                   enrolled?.account
                              )"
                              :key="key"
                              class="w-full"
                         >
                              <tooltip
                                   position="top"
                                   class="!w-full"
                                   :disable="
                                        !disconnected_code.includes(
                                             parseInt(item.accountStatus)
                                        )
                                             ? true
                                             : false
                                   "
                                   :class="
                                        !disconnected_code.includes(
                                             parseInt(item.accountStatus)
                                        )
                                             ? '!opacity-100'
                                             : ''
                                   "
                              >
                                   <template v-slot:text>
                                        <div
                                             @click="
                                                  () => {
                                                       disconnected_code.includes(
                                                            parseInt(
                                                                 item.accountStatus
                                                            )
                                                       )
                                                            ? null
                                                            : setSelectedWallet(
                                                                   item
                                                              );
                                                  }
                                             "
                                             class="flex flex-row items-center justify-between w-full gap-2 px-4 py-3 rounded-lg transition-all duration-300 ease-out bg-gray-150 border border-solid"
                                             :class="[
                                                  selectedWallet?.fdAccountID ==
                                                  item.fdAccountID
                                                       ? 'border-background-7'
                                                       : 'border-shade-10',
                                                  disconnected_code.includes(
                                                       parseInt(
                                                            item.accountStatus
                                                       )
                                                  )
                                                       ? 'cursor-not-allowed !border-shade-10'
                                                       : 'cursor-pointer'
                                             ]"
                                        >
                                             <div class="flex flex-row w-full">
                                                  <span
                                                       class="w-full flex flex-row gap-3 justify-start items-center text-black text-xs md:text-sm lg:text-xs xl:text-sm"
                                                       :class="{
                                                            'opacity-50':
                                                                 disconnected_code.includes(
                                                                      parseInt(
                                                                           item.accountStatus
                                                                      )
                                                                 )
                                                       }"
                                                  >
                                                       <img
                                                            :src="
                                                                 baseUrl.img +
                                                                 '/assets/img/card/bank-icon.png'
                                                            "
                                                            class="h-6"
                                                       />
                                                       <div
                                                            class="flex flex-col"
                                                       >
                                                            <span
                                                                 class="w-full capitalize text-nowrap text-ellipsis overflow-hidden font-bold text-moozi-1"
                                                            >
                                                                 {{
                                                                      item
                                                                           .userIdentificationDetails
                                                                           .bankName
                                                                 }}
                                                                 <span
                                                                      class="uppercase font-normal rounded-full md:px-2 text-mid py-0 w-2 md:w-auto h-2 md:h-max text-transparent md:text-white"
                                                                      :class="
                                                                           disconnected_code.includes(
                                                                                parseInt(
                                                                                     item.accountStatus
                                                                                )
                                                                           )
                                                                                ? 'bg-background-4'
                                                                                : 'bg-shade-14`'
                                                                      "
                                                                 >
                                                                      {{
                                                                           disconnected_code.includes(
                                                                                parseInt(
                                                                                     item.accountStatus
                                                                                )
                                                                           )
                                                                                ? "Reconnect"
                                                                                : "Active"
                                                                      }}
                                                                 </span>
                                                            </span>
                                                            <span>
                                                                 Account ending
                                                                 in
                                                                 <span
                                                                      class="font-bold"
                                                                 >
                                                                      {{
                                                                           item
                                                                                .userIdentificationDetails
                                                                                .bankAccountActualLastFour
                                                                      }}
                                                                 </span>
                                                            </span>
                                                       </div>
                                                  </span>
                                             </div>
                                             <div
                                                  class="flex flex-row h-full gap-3 items-end"
                                             >
                                                  <!--  -->
                                                  <!-- v-if="
                                                            disconnected_code.includes(
                                                                 parseInt(
                                                                      item.accountStatus
                                                                 )
                                                            )
                                                       " -->
                                                  <span
                                                       @click="
                                                            (e) => {
                                                                 e.stopPropagation();
                                                                 e.preventDefault();
                                                                 fetchSDK(
                                                                      'relink',
                                                                      item.fdAccountID
                                                                 );
                                                            }
                                                       "
                                                       class="cursor-pointer bg-background-4 rounded-md p-1 transition-transform duration-300 ease-in-out scale-100 lg:hover:scale-105"
                                                  >
                                                       <span
                                                            class="underline underline-offset-2 cursor-pointer flex flex-col justify-end items-start text-black text-xs lg:text-sm"
                                                       >
                                                            <svg
                                                                 class="w-5 h-5"
                                                                 viewBox="0 0 24 24"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                 <path
                                                                      d="M19.9329 13.0412C19.7441 14.4811 19.1669 15.8425 18.2631 16.9793C17.3594 18.1162 16.1632 18.9855 14.8029 19.4942C13.4426 20.0029 11.9695 20.1317 10.5416 19.8667C9.11368 19.6018 7.7848 18.9532 6.69749 17.9905C5.61018 17.0277 4.80545 15.7872 4.36961 14.4018C3.93377 13.0165 3.88326 11.5386 4.22349 10.1268C4.56373 8.71488 5.28188 7.42227 6.30091 6.38752C7.31995 5.35278 8.60144 4.61495 10.0079 4.25317C13.9069 3.25317 17.9429 5.26017 19.4329 9.00017M20 4V9H15"
                                                                      stroke="#FFF"
                                                                      stroke-width="2"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                 />
                                                            </svg>
                                                       </span>
                                                  </span>
                                                  <span
                                                       v-if="
                                                            !disconnected_code.includes(
                                                                 parseInt(
                                                                      item.accountStatus
                                                                 )
                                                            )
                                                       "
                                                       @click="
                                                            (e) => {
                                                                 e.stopPropagation();
                                                                 e.preventDefault();
                                                                 fetchSDK(
                                                                      'edit',
                                                                      item.fdAccountID
                                                                 );
                                                            }
                                                       "
                                                       class="cursor-pointer bg-blue-50 opacity-85 rounded-md p-1 transition-transform duration-300 ease-in-out scale-100 lg:hover:scale-105"
                                                  >
                                                       <span
                                                            class="underline underline-offset-2 cursor-pointer flex flex-col justify-end items-start text-black text-xs lg:text-sm"
                                                       >
                                                            <svg
                                                                 class="w-5 h-5"
                                                                 viewBox="0 0 24 24"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                 <path
                                                                      d="M7 7H6C5.46957 7 4.96086 7.21071 4.58579 7.58579C4.21071 7.96086 4 8.46957 4 9V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H15C15.5304 20 16.0391 19.7893 16.4142 19.4142C16.7893 19.0391 17 18.5304 17 18V17M16 5L19 8M20.385 6.58511C20.7788 6.19126 21.0001 5.65709 21.0001 5.10011C21.0001 4.54312 20.7788 4.00895 20.385 3.61511C19.9912 3.22126 19.457 3 18.9 3C18.343 3 17.8088 3.22126 17.415 3.61511L9 12.0001V15.0001H12L20.385 6.58511Z"
                                                                      stroke="#FFF"
                                                                      stroke-width="2"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                 />
                                                            </svg>
                                                       </span>
                                                  </span>
                                                  <span
                                                       v-if="
                                                            !disconnected_code.includes(
                                                                 parseInt(
                                                                      item.accountStatus
                                                                 )
                                                            )
                                                       "
                                                       @click="
                                                            (e) => {
                                                                 e.stopPropagation();
                                                                 e.preventDefault();
                                                                 fetchSDK(
                                                                      'close',
                                                                      item.fdAccountID
                                                                 );
                                                            }
                                                       "
                                                       class="cursor-pointer bg-red-40 opacity-85 rounded-md p-1 transition-transform duration-300 ease-in-out scale-100 lg:hover:scale-105"
                                                  >
                                                       <span
                                                            class="underline underline-offset-2 cursor-pointer flex flex-col justify-end items-start text-black text-xs lg:text-sm"
                                                       >
                                                            <svg
                                                                 class="w-5 h-5"
                                                                 viewBox="0 0 24 24"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                 <path
                                                                      d="M19.9329 13.0412C19.7441 14.4811 19.1669 15.8425 18.2631 16.9793C17.3594 18.1162 16.1632 18.9855 14.8029 19.4942C13.4426 20.0029 11.9695 20.1317 10.5416 19.8667C9.11368 19.6018 7.7848 18.9532 6.69749 17.9905C5.61018 17.0277 4.80545 15.7872 4.36961 14.4018C3.93377 13.0165 3.88326 11.5386 4.22349 10.1268C4.56373 8.71488 5.28188 7.42227 6.30091 6.38752C7.31995 5.35278 8.60144 4.61495 10.0079 4.25317C13.9069 3.25317 17.9429 5.26017 19.4329 9.00017M20 4V9H15"
                                                                      stroke="#FFF"
                                                                      stroke-width="2"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                 />
                                                            </svg>
                                                       </span>
                                                  </span>
                                             </div>
                                        </div>
                                   </template>
                                   <template v-slot:tooltip>
                                        <div class="w-64 lg:w-full p-2">
                                             This card is need to reconnect!.
                                        </div>
                                   </template>
                              </tooltip>
                         </div>
                    </div>
               </div>
               <div
                    v-else-if="enrolled == null"
                    class="h-full w-full"
               >
                    <div
                         v-if="loading"
                         class="flex item-center justify-center w-full py-32"
                    >
                         <svg
                              aria-hidden="true"
                              class="w-8 h-8 text-black animate-spin fill-secondary-1"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                   fill="currentColor"
                              ></path>
                              <path
                                   d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                   fill="currentFill"
                              ></path>
                         </svg>
                    </div>
               </div>
               <div
                    v-else
                    class="h-full flex flex-col"
               >
                    <p
                         class="text-xs md:text-sm lg:text-xs xl:text-sm mb-5 text-black font-bold"
                    >
                         Current account
                    </p>
                    <div
                         class="border border-shade-10 py-3 px-4 w-full text-center text-shade-10 text-sm lg:text-base rounded-lg mb-4"
                    >
                         No bank connected. Please link new account.
                    </div>
               </div>
          </div>
          <div class="flex flex-col">
               <transition
                    enter-from-class="opacity-0"
                    enter-active-class="transition ease-in duration-300"
                    enter-to-class="opacity-100 scale-100"
               >
                    <div
                         v-if="
                              dailyLimit != null &&
                              limitReachValue + coins.total > dailyLimit
                         "
                         class="rounded-xl !mt-4 bg-primal-yellow text-shade-19 flex flex-row items-center gap-2 ease-in-out transition-all duration-300 p-3 mb-4 w-full"
                    >
                         <svg
                              class="w-5 h-5"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M10 7V11M10 14V14.01M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z"
                                   stroke="#FFF2B1"
                                   stroke-width="2"
                                   stroke-linecap="round"
                                   stroke-linejoin="round"
                              />
                         </svg>
                         <p class="text-sm lg:text-base font-medium">
                              {{
                                   limitReachValue >= dailyLimit
                                        ? "You have already exceeded your set purchase limit. "
                                        : "This purchasewill exceed your purchase limit. "
                              }}
                              <span
                                   class="underline underline-offset-2 cursor-pointer"
                                   @click="gotoResponsiblePlay"
                                   >Go to Responsible Play</span
                              >
                         </p>
                    </div>
               </transition>
               <transition
                    enter-from-class="opacity-0"
                    enter-active-class="transition ease-in duration-300"
                    enter-to-class="opacity-100 scale-100"
               >
                    <div
                         v-if="checkActionRestricted()"
                         class="rounded-xl !mt-0 bg-primal-yellow text-shade-19 flex flex-row items-center gap-2 ease-in-out transition-all duration-300 p-3 mb-4 w-full"
                    >
                         <svg
                              class="w-5 h-5"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M10 7V11M10 14V14.01M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z"
                                   stroke="#FFF2B1"
                                   stroke-width="2"
                                   stroke-linecap="round"
                                   stroke-linejoin="round"
                              />
                         </svg>
                         <p class="text-sm lg:text-base font-medium">
                              An error has occurred, please contact support.
                         </p>
                    </div>
               </transition>
               <div
                    v-if="walletCount == 0"
                    class="w-full mt-5"
               >
                    <span
                         @click="() => fetchSDK('bank')"
                         class="font-bold underline text-base cursor-pointer text-moozi-1"
                    >
                         <span class="text-lg">+</span>
                         <span class="text-sm xl:text-base">
                              Connect New Account
                         </span>
                    </span>
               </div>
               <button
                    @click="openConfirmationModal()"
                    :disabled="
                         selectedWallet != null &&
                         !checkActionRestricted() &&
                         !(
                              dailyLimit != null &&
                              limitReachValue + coins.total > dailyLimit
                         )
                              ? false
                              : true
                    "
                    :class="
                         selectedWallet != null &&
                         !checkActionRestricted() &&
                         !(
                              dailyLimit != null &&
                              limitReachValue + coins.total > dailyLimit
                         )
                              ? 'bg-secondary-3 text-primary-1 cursor-pointer'
                              : 'bg-gray-190 text-shade-7 cursor-not-allowed'
                    "
                    class="w-full mt-5 rounded-full font-bold tracking-wider px-10 py-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
               >
                    Next
               </button>
          </div>
     </div>
     <WalletConfirmation
          :onClickPurchaseCoin="makePurchase"
          :selectedWallet="selectedWallet ?? {}"
          :coins="coins"
          :purchase_loading="purchase_loading"
          :initRecaptcha="initRecaptcha"
     />
</template>
<script>
import { onMounted, reactive, watch, ref } from "vue";
import {
     initConnectPay as initConnectPayAPI,
     getNonDoc as getNonDocAPI,
     makePurchase as makePurchaseAPI,
     enrollmentLogs as enrollmentLogsAPI
} from "api/connect_pay.js";
import { authStore } from "store/auth.js";
import { computed } from "vue";
import { connectPayStore } from "store/connectPay.js";
import _ from "underscore";
import eventBus from "plugins/event.js";
import WalletConfirmation from "components/modals/walletConfimation.vue";
import { useToaster } from "composables/use-toaster.js";
import { useFormatter } from "composables/use-formatter.js";
import { fiservUrl } from "config/services.js";
import { baseUrl } from "config/services.js";
import { useKYC } from "composables/use-kyc.js";
import { useActionRestriction } from "composables/use-restriction.js";
import { useRouter } from "vue-router";
import { coinsStore } from "store/coins";
import { useGTag } from "composables/use-gtag.js";

export default {
     props: [
          "coins",
          "setLoading",
          "setPaymentSuccessCoins",
          "closeModal",
          "gtagSelected"
     ],
     components: {
          WalletConfirmation
     },
     setup(props) {
          const { open_kyc_bank } = useKYC();
          const { isActionRestricted } = useActionRestriction();

          var extraParams = reactive({
               firstName: "",
               lastName: "",
               email: "",
               street: "",
               city: "",
               state: "",
               postalCode: ""
          });

          const initRecaptcha = ref(false);

          const { gTagAddPaymentInfo, gTagPurchase, gTagSelectItemList } =
               useGTag();
          const { successToast, errorToast } = useToaster();
          const { removeUSAPrefix } = useFormatter();
          const router = useRouter();

          const useAuthStore = authStore();
          const useconnectPayStore = connectPayStore();
          const useCoinsStore = coinsStore();

          const isAddAccountPanelOpen = ref(false);
          const loading = ref(false);

          const purchase_loading = ref(false);

          const modal_loading = ref(false);

          const selectedWallet = ref(null);

          const statusMsg = ["ERROR", "DECLINED"];

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const enrolled = computed(() => {
               return useconnectPayStore.enrolled;
          });

          const active_code = computed(() => {
               return useconnectPayStore.active_code;
          });

          const decline_code = computed(() => {
               return useconnectPayStore.decline_code;
          });

          const disconnected_code = computed(() => {
               return useconnectPayStore.disconnected_code;
          });

          const walletCount = computed(() => {
               return useconnectPayStore.walletCount;
          });

          const limitReachValue = computed(() => {
               return useCoinsStore.limitReachValue;
          });

          const dailyLimit = computed(() => {
               return useAuthStore.dailyLimit;
          });

          const getSDKConfig = (data, type, accountId) => {
               const params = {
                    accessToken: data["currentSession"]["security"]["tokenID"],
                    fdCustomerId: ["bank", "manual"].includes(type)
                         ? data["fdCustomerID"]
                         : enrolled.value.customer.fdCustomerID,
                    encryptionKey:
                         data["currentSession"]["security"]["publicKey"]
               };

               switch (type) {
                    case "bank":
                         params["configId"] = fiservUrl.bank;
                         break;
                    case "manual":
                         params["configId"] = fiservUrl.manual;
                         break;
                    case "edit":
                         params["configId"] = fiservUrl.edit;
                         break;
                    case "close":
                         params["configId"] = fiservUrl.close;
                         break;
                    default:
                         break;
               }

               if (accountId != null) {
                    params["accountID"] = accountId;
               }

               return params;
          };

          const getData = async () => {
               const non_doc = _.filter(isUser.value.kyc, function (params) {
                    return params["type"].toLowerCase() == "usa non-doc";
               });

               let jsonAddress = null;
               let jsonUser = null;

               if (non_doc.length >= 1) {
                    const json = JSON.parse(non_doc[0].extracted_docs);

                    if (json) {
                         if (json.extractedDoc.addresses.length >= 1) {
                              jsonAddress = json.extractedDoc.addresses[0];
                         }

                         jsonUser = json.extractedDoc;
                    } else {
                         const temp = await getNonDoc(non_doc[0].applicant_id);

                         if (!temp?.status) {
                              errorToast("Invalid KYC", "top-right");
                              setTimeout(() => {
                                   location.reload();
                                   props.setLoading(false);
                              }, 1500);
                         } else {
                              const json = JSON.parse(temp.extracted_docs);

                              if (json.extractedDoc.addresses.length >= 1) {
                                   jsonAddress = json.extractedDoc.addresses[0];
                              }
                         }
                    }
               }

               return {
                    address: jsonAddress,
                    user: jsonUser
               };
          };

          const getNonDoc = async (id) => {
               return await getNonDocAPI(id)
                    .then(async (res) => {
                         return res;
                    })
                    .catch(async (res) => {
                         return res;
                    });
          };

          const setExtraParams = async () => {
               const data = await getData();

               extraParams.firstName = data.user.firstName;
               extraParams.lastName = data.user.lastName;
               extraParams.email = data.user.email ?? isUser.value.email;
               extraParams["userPhone[0].type"] = "HOME";
               extraParams["userPhone[0].primary"] = "Y";
               extraParams["userPhone[0].number"] = "+" + data.user.phone;

               extraParams.street = data.address.street;
               extraParams.city = data.address.town;
               extraParams.state = data.address.state;

               if (data.address.postCode) {
                    let splitPostcode = data.address.postCode.split("-");
                    extraParams.postalCode = splitPostcode[0];
                    // extraParams.postalCode = data.address.postCode;
               }
               if (extraParams["userPhone[0].number"]) {
                    extraParams["userPhone[0].number"] = removeUSAPrefix(
                         extraParams["userPhone[0].number"]
                    );
               }

               if (typeof data.user.tin !== "undefined") {
                    extraParams.ssn = data.user.tin;
               }

               return extraParams;
          };

          const fetchSDK = async (type, accountId = null) => {
               if (open_kyc_bank() == true) {
                    return;
               }

               let params = {};
               if (["bank", "manual"].includes(type)) {
                    if (walletCount.value >= 1) {
                         return;
                    }
                    isAddAccountPanelOpen.value = true;
                    loading.value = true;
               } else {
                    params = { fdAccountID: accountId };
               }

               props.setLoading(true);
               modal_loading.value = true;

               await initConnectPayAPI(params)
                    .then(async (res) => {
                         if (res) {
                              let CP = await CPSDK(res["api_key"], true);

                              const sdkConfiguration = getSDKConfig(
                                   res,
                                   type,
                                   accountId
                              );

                              // console.log(sdkConfiguration);

                              const extraData = await setExtraParams(res);

                              console.log(extraData);

                              if (
                                   extraData["city"] == null ||
                                   extraData["city"] == "" ||
                                   extraData["state"] == null ||
                                   extraData["state"] == "" ||
                                   extraData["postalCode"] == null ||
                                   extraData["postalCode"] == "" ||
                                   extraData["street"] == null ||
                                   extraData["street"] == ""
                              ) {
                                   errorToast(
                                        "Address Incomplete!",
                                        "top-right"
                                   );
                                   props.setLoading(false);
                                   modal_loading.value = false;
                                   return;
                              }

                              if (type == "bank") {
                                   gTagAddPaymentInfo(
                                        props.gtagSelected?.price,
                                        "Bank Transfer",
                                        props.gtagSelected
                                   );
                                   props.setLoading(false);
                                   await addBank(
                                        CP,
                                        sdkConfiguration,
                                        extraData
                                   );
                              } else if (type == "manual") {
                                   props.setLoading(false);
                                   await manualBank(
                                        CP,
                                        sdkConfiguration,
                                        extraData
                                   );
                              } else if (type == "edit") {
                                   props.setLoading(false);
                                   await updateBank(
                                        CP,
                                        sdkConfiguration,
                                        extraData
                                   );
                              } else if (type == "close") {
                                   props.setLoading(false);
                                   await closeBank(
                                        CP,
                                        sdkConfiguration,
                                        extraData
                                   );
                              } else if (type == "relink") {
                                   props.setLoading(false);
                                   await relinkBank(
                                        CP,
                                        sdkConfiguration,
                                        extraData
                                   );
                              }
                         }
                    })
                    .catch(() => {
                         props.setLoading(false);
                    });
          };

          const makePurchase = async (accountId, tokenResponse) => {
               purchase_loading.value = true;
               const params = {
                    accountId: accountId
               };

               if (props.coins["action"] == "package") {
                    params["coin_id"] = props.coins["coin_id"];
               } else {
                    params["money"] = parseInt(props.coins["total"]);
               }

               params["g-recaptcha-response"] = tokenResponse;

               await makePurchaseAPI(params)
                    .then(async (res) => {
                         await useCoinsStore.getLimitReach(true);
                         purchase_loading.value = false;
                         closeConfirmationModal();
                         props.closeModal();

                         const json = JSON.parse(res.notes);

                         openPaymentSuccess(
                              {
                                   gc: res.gold_coins,
                                   sc: res.silver_coins
                              },
                              json.transaction_id
                         );
                         props.setPaymentSuccessCoins(res);
                    })
                    .catch((error) => {
                         const { response } = error || {};
                         const status = response?.status;
                         const data = response?.data;
                         if (status == 403) {
                              window.location.href = "/";
                              return;
                         }

                         purchase_loading.value = false;
                         closeConfirmationModal();
                         props.closeModal();
                         openPaymentFailed(data?.message ?? null);
                    });
          };

          const openPaymentSuccess = (coins, transaction_id) => {
               gTagPurchase(
                    transaction_id,
                    props.gtagSelected?.price,
                    props.gtagSelected
               );
               gTagSelectItemList(props.gtagSelected);
               eventBus.emit("open:payment_success", coins);
          };

          const openPaymentFailed = (message = null) => {
               eventBus.emit("open:payment_failed", message);
          };

          const openBankWallet = () => {
               eventBus.emit("open:bank_wallet");
          };

          const closeBankWallet = () => {
               eventBus.emit("close:bank_wallet");
          };

          const openManualWallet = () => {
               eventBus.emit("open:manual_wallet");
          };

          const closeManualWallet = () => {
               eventBus.emit("close:manual_wallet");
          };

          const openUpdateWallet = () => {
               eventBus.emit("open:update_wallet");
          };

          const closeUpdateWallet = () => {
               eventBus.emit("close:update_wallet");
          };

          const openRemoveWallet = () => {
               eventBus.emit("open:remove_wallet");
          };

          const closeRemoveWallet = () => {
               eventBus.emit("close:remove_wallet");
          };

          const openConfirmationModal = () => {
               if (
                    dailyLimit.value != null &&
                    limitReachValue.value + props.coins.total > dailyLimit.value
               ) {
                    errorToast(
                         "This purchase is beyond your set purchase limit!",
                         "top-right"
                    );
                    return;
               }
               initRecaptcha.value = true;
               eventBus.emit("open:walletconfirmation");
          };

          const closeConfirmationModal = () => {
               initRecaptcha.value = false;
               eventBus.emit("close:walletconfirmation");
          };

          const addBank = async (CP, sdkConfiguration, extraData) => {
               console.log("===============================================");
               console.log("STREAMLINE ENROLLMENT() - LINK BANK ACCOUNT");
               console.log(sdkConfiguration);
               console.log(extraData);
               //    32678005-8e7f-4d12-8298-cd40e103866a
               openBankWallet();
               const addBank = await CP.StreamlinedEnrollment(
                    sdkConfiguration,
                    extraData,
                    async function (response) {
                         const res =
                              typeof response == "object"
                                   ? response
                                   : JSON.parse(response);

                         getEnrollmentLogs(response);

                         if (
                              res.transactionStatusDescription ==
                              "User Cancelled"
                         ) {
                              //   isAddAccountPanelOpen.value = false;
                              modal_loading.value = true;
                              closeBankWallet();
                         } else if (statusMsg.includes(res.transactionStatus)) {
                              console.error(res.transactionStatusDescription);
                              errorToast(
                                   res.transactionStatusDescription,
                                   "top-right"
                              );
                              modal_loading.value = true;
                              fetchSDK("bank");
                         } else {
                              successToast(
                                   "Successfully Linked Bank Account!",
                                   "top-right"
                              );

                              purchase_loading.value = true;
                              const saveOldEnrolledList =
                                   enrolled.value?.account;
                              await useconnectPayStore.getEnrolled(true);
                              await useconnectPayStore.getWalletRedeem(true);
                              const newEnrolledList = enrolled.value?.account;

                              const pluckOld = _.pluck(
                                   saveOldEnrolledList,
                                   "fdAccountID"
                              );
                              const pluckNew = _.pluck(
                                   newEnrolledList,
                                   "fdAccountID"
                              );

                              closeBankWallet();
                              purchase_loading.value = false;

                              const dif = _.difference(pluckNew, pluckOld);
                              console.log(dif);

                              const filter = _.filter(
                                   newEnrolledList,
                                   function (params) {
                                        return params["fdAccountID"] == dif[0];
                                   }
                              );

                              setSelectedWallet(filter[0]);
                              openConfirmationModal();
                         }
                    }
               );

               addBank.start("sdk-container-bank");
               loading.value = false;
          };

          const manualBank = async (CP, sdkConfiguration, extraData) => {
               console.log("===============================================");
               console.log("STREAMLINE ENROLLMENT() - MANUAL");
               console.log(sdkConfiguration);
               console.log(extraData);
               openManualWallet();
               const addBank = await CP.StreamlinedEnrollment(
                    sdkConfiguration,
                    extraData,
                    async function (response) {
                         const res =
                              typeof response == "object"
                                   ? response
                                   : JSON.parse(response);

                         getEnrollmentLogs(response);

                         if (
                              res.transactionStatusDescription ==
                              "User Cancelled"
                         ) {
                              //   isAddAccountPanelOpen.value = false;
                              closeManualWallet();
                         } else if (statusMsg.includes(res.transactionStatus)) {
                              console.error(res.transactionStatusDescription);
                              errorToast(
                                   res.transactionStatusDescription,
                                   "top-right"
                              );
                              fetchSDK("manual");
                         } else {
                              console.log(response);
                              successToast(
                                   "Successfully Enrolled!",
                                   "top-right"
                              );
                              await useconnectPayStore.getEnrolled(true);
                              await useconnectPayStore.getWalletRedeem(true);
                         }
                    }
               );

               addBank.start("sdk-container-manual");
               loading.value = false;
          };

          const updateBank = async (CP, sdkConfiguration, extraData) => {
               console.log("===============================================");
               console.log("UPDATEENROLLMENT()");
               console.log(sdkConfiguration);
               console.log(extraData);
               openUpdateWallet();

               const editBank = await CP.UpdateEnrollment(
                    sdkConfiguration,
                    extraData,
                    async function (response) {
                         const res =
                              typeof response == "object"
                                   ? response
                                   : JSON.parse(response);

                         if (
                              res.transactionStatusDescription ==
                              "User Cancelled"
                         ) {
                              closeUpdateWallet();
                         } else if (statusMsg.includes(res.transactionStatus)) {
                              console.error(res.transactionStatusDescription);
                              errorToast(
                                   res.transactionStatusDescription,
                                   "top-right"
                              );
                              fetchSDK("edit");
                         } else {
                              console.log(response);
                              successToast(
                                   "Successfully Updated!",
                                   "top-right"
                              );
                         }
                    }
               );

               editBank.start("sdk-container-update");
               loading.value = false;
          };

          const closeBank = async (CP, sdkConfiguration, extraData) => {
               console.log("===============================================");
               console.log("CLOSEACCOUNT()");
               console.log(sdkConfiguration);
               console.log(extraData);
               openRemoveWallet();

               const removeBank = await CP.CloseAccount(
                    sdkConfiguration,
                    extraData,
                    async function (response) {
                         const res =
                              typeof response == "object"
                                   ? response
                                   : JSON.parse(response);

                         if (
                              res.transactionStatusDescription ==
                              "User Cancelled"
                         ) {
                              closeRemoveWallet();
                         } else if (statusMsg.includes(res.transactionStatus)) {
                              console.error(res.transactionStatusDescription);
                              errorToast(
                                   res.transactionStatusDescription,
                                   "top-right"
                              );
                              fetchSDK("close");
                         } else {
                              console.log(response);
                              purchase_loading.value = true;
                              successToast("Successfully Closed!", "top-right");
                              await useconnectPayStore.getEnrolled(true);
                              await useconnectPayStore.getWalletRedeem(true);
                              purchase_loading.value = false;
                              closeRemoveWallet();
                         }
                    }
               );

               removeBank.start("sdk-container-remove");
               loading.value = false;
          };

          const relinkBank = async (CP, sdkConfiguration, extraData) => {
               console.log("===============================================");
               console.log("RECONNECT()");
               console.log(sdkConfiguration);
               console.log(extraData);
               openRemoveWallet();

               const removeBank = await CP.Relink(
                    sdkConfiguration,
                    extraData,
                    async function (response) {
                         const res =
                              typeof response == "object"
                                   ? response
                                   : JSON.parse(response);

                         if (
                              res.transactionStatusDescription ==
                              "User Cancelled"
                         ) {
                              closeRemoveWallet();
                         } else if (statusMsg.includes(res.transactionStatus)) {
                              console.error(res.transactionStatusDescription);
                              errorToast(
                                   res.transactionStatusDescription,
                                   "top-right"
                              );
                              fetchSDK("close");
                         } else {
                              console.log(response);
                              purchase_loading.value = true;
                              successToast("Successfully Closed!", "top-right");
                              await useconnectPayStore.getEnrolled(true);
                              await useconnectPayStore.getWalletRedeem(true);
                              purchase_loading.value = false;
                              closeRemoveWallet();
                         }
                    }
               );

               removeBank.start("sdk-container-remove");
               loading.value = false;
          };

          const getEnrollmentLogs = async (params) => {
               const temp = {
                    response_data: JSON.parse(params)
               };

               console.log(JSON.parse(params));

               await enrollmentLogsAPI(temp)
                    .then(() => {
                         console.log("successfully log");
                    })
                    .catch((error) => {
                         console.error(error);
                    });
          };

          const setSelectedWallet = (data) => {
               selectedWallet.value = data;
               gTagAddPaymentInfo(
                    props.gtagSelected?.price,
                    "Bank Transfer",
                    props.gtagSelected
               );
          };

          const getActiveAccount = (data = []) => {
               return data;
               // const temp = _.filter(data, function (params) {
               //      return params["accountStatus"] != "40";
               // });

               // return temp.length != 0 ? temp : [];
          };

          const checkAPButton = async () => {
               const apButtonTab = document.getElementById("ap-button-tab");
               const apButtonPanel = document.getElementById("ap-button-panel");
               if (apButtonTab && apButtonPanel) {
                    if (
                         window?.ApplePaySession &&
                         ApplePaySession?.canMakePayments()
                    ) {
                         apButtonTab?.classList.remove("hidden");
                         apButtonPanel?.classList.remove("hidden");
                    } else {
                         apButtonTab?.remove();
                         apButtonPanel?.remove();
                    }
               }
          };

          const checkActionRestricted = () => {
               // DEPOSIT
               const restrictedActionType = 1;

               return isActionRestricted(restrictedActionType);
          };

          const gotoResponsiblePlay = () => {
               router.push({
                    path: "/account",
                    query: { tab: "responsible-play" },
                    force: true
               });
          };

          onMounted(async () => {
               setTimeout(() => {
                    checkAPButton();
               }, 500);

               const isExist = document.querySelector("#fiserv-import");
               if (isExist == null) {
                    var script = document.createElement("script");
                    script.id = "fiserv-import";
                    script.src =
                         import.meta.env.VITE_ENV == "production"
                              ? "https://prod.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js"
                              : "https://cat.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js";
                    document.body.appendChild(script);
               }

               loading.value = true;
               await useconnectPayStore.getEnrolled();
               if (enrolled.value != null && enrolled.value?.account?.length) {
                    if (
                         walletCount.value == 0 &&
                         enrolled.value?.account[0].accountStatus == "40"
                    )
                         return;
                    else {
                         const temp = _.filter(
                              enrolled.value?.account,
                              function (params) {
                                   return (
                                        params["accountStatus"] != "40" &&
                                        !disconnected_code.value.includes(
                                             parseInt(params["accountStatus"])
                                        )
                                   );
                              }
                         );

                         if (temp.length != 0) {
                              selectedWallet.value = temp[0];
                              gTagAddPaymentInfo(
                                   props.gtagSelected?.price,
                                   "Bank Transfer",
                                   props.gtagSelected
                              );
                         } else {
                              selectedWallet.value = null;
                         }
                    }
               }
               await useAuthStore.getUser();
               loading.value = false;
          });

          watch(isUser, (newVal) => {
               if (newVal) {
                    console.log(newVal);
               }
          });

          // watch(isUser, (newVal) => {
          //      if (newVal) {
          //           console.log(newVal);
          //      }
          // });

          return {
               enrolled,
               active_code,
               decline_code,
               disconnected_code,
               walletCount,
               fetchSDK,
               isAddAccountPanelOpen,
               loading,
               makePurchase,
               purchase_loading,
               selectedWallet,
               setSelectedWallet,
               openConfirmationModal,
               baseUrl,
               getActiveAccount,
               removeUSAPrefix,
               checkActionRestricted,
               initRecaptcha,
               gotoResponsiblePlay,
               limitReachValue,
               dailyLimit
          };
     }
};
</script>
