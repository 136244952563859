<template>
     <div
          class="snowContainer overflow-hidden"
          ref="snowContainer"
     >
          <!-- Snowflakes will be dynamically added -->
     </div>
</template>
<script>
import { ref } from "vue";
import { onMounted } from "vue";
import { gsap } from "gsap";

export default {
     setup() {
          const snowContainer = ref(null);

          const animateSnow = () => {
               const createSnowflake = () => {
                    const flake = document.createElement("div");
                    flake.classList.add("snowflake");

                    const size = Math.random() * 10;
                    const startX = Math.random() * window.innerWidth;
                    const endX = startX + (Math.random() * 100 - 50);
                    const duration = Math.random() * 5 + 3;
                    const opacity = Math.random() * 0.5 + 0.5;

                    Object.assign(flake.style, {
                         position: "fixed",
                         top: "-20px",
                         left: `${startX}px`,
                         width: `${size}px`,
                         height: `${size}px`,
                         backgroundColor: "white",
                         borderRadius: "50%",
                         opacity,
                         pointerEvents: "none",
                         zIndex: "0"
                    });

                    snowContainer.value.appendChild(flake);

                    gsap.to(flake, {
                         y: window.innerHeight + 20,
                         x: endX,
                         duration,
                         ease: "none",
                         onComplete: () => {
                              flake.remove();
                         }
                    });

                    // Schedule the next snowflake
                    setTimeout(createSnowflake, Math.random() * 200); // Delay between flakes (up to 300ms)
               };

               // Start the recursive snowflake creation
               createSnowflake();
          };

          onMounted(() => {
               animateSnow();
          });

          return {
               snowContainer
          };
     }
};
</script>

<style>
/* Add any additional styling for snowflakes */
</style>
