import { defineStore } from "pinia";
import {
     getAccount as getAccountAPI,
     getWallet as getWalletAPI
} from "api/connect_pay.js";
import _ from "underscore";
import { authStore } from "store/auth.js";

export const connectPayStore = defineStore("connectPay", {
     state: () => {
          return {
               enrolled: null,
               walletCount: 0,
               walletRedeem: null,
               walletRedeemCount: 0,
               active_code: [],
               decline_code: [],
               disconnected_code: []
          };
     },
     actions: {
          getEnrolled(force = false) {
               if (!force && this.enrolled != null) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    getAccountAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   if (response?.enrolled) {
                                        state.enrolled =
                                             response?.enrolled?.response ?? [];
                                        const filter = _.filter(
                                             response?.enrolled?.response
                                                  .account,
                                             function (params) {
                                                  return (
                                                       params[
                                                            "accountStatus"
                                                       ] != "40"
                                                  );
                                             }
                                        );
                                        state.walletCount = filter.length;
                                        state.active_code =
                                             response?.active_code;
                                        state.decline_code =
                                             response?.decline_code;
                                        state.disconnected_code =
                                             response?.disconnected_code;
                                   } else {
                                        state.enrolled = [];
                                        state.walletCount = 0;
                                   }
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (
                                        error.response.status == 503 &&
                                        ["time", "days"].includes(
                                             error.response.data?.type
                                        )
                                   ) {
                                        const useAuthStore = authStore();

                                        useAuthStore.setSelfExclusionTimer(
                                             error.response.data
                                        );
                                        return;
                                   }

                                   state.enrolled = [];
                                   state.walletCount = 0;
                              });
                              console.error("Error to load coins data!", error);
                              reject(error);
                         });
               });
          },
          getWalletRedeem(force = false) {
               if (!force && this.walletRedeem != null) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    getWalletAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   if (response?.enrolled) {
                                        state.walletRedeem =
                                             response?.enrolled?.response ?? [];
                                        const filter = _.filter(
                                             response?.enrolled?.response
                                                  .account,
                                             function (params) {
                                                  return (
                                                       params[
                                                            "accountStatus"
                                                       ] != "40"
                                                  );
                                             }
                                        );
                                        state.walletRedeemCount = filter.length;
                                        state.active_code =
                                             response?.active_code;
                                        state.decline_code =
                                             response?.decline_code;
                                        state.disconnected_code =
                                             response?.disconnected_code;
                                   } else {
                                        state.walletRedeem = [];
                                        state.walletRedeemCount = 0;
                                   }
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   state.walletRedeem = [];
                                   state.walletRedeemCount = 0;
                              });
                              console.error("Error to load coins data!", error);
                              reject(error);
                         });
               });
          }
     }
});
